import { ref } from 'vue'
import { useQuery, keepPreviousData } from '@tanstack/vue-query'
import useNewsApi from './useNewsApi'
import { setCookie, getCookie } from '@/utils/cookieStorage'

const useNews = () => {
  const VIEWS_COOKIE_NAME = 'VIEWED_NEWS_IDS'
  const queryKey = ref('news')
  const pageRef = ref(1)
  const filterRef = ref({
    search: '',
    date: undefined,
    tags: [],
    categories: [],
  })

  const { index, show, errors, incrementViews } = useNewsApi()

  // INDEX
  const useIndex = (page = pageRef, filters = filterRef) => {
    const {
      data,
      error,
      isPending,
      isFetching,
      isError,
      refetch,
      isPlaceholderData,
    } = useQuery({
      queryKey: [queryKey.value, { page }, { filters }],
      queryFn: () => index(page, filters),
      refetchOnMount: false,
      staleTime: 0,
      placeholderData: keepPreviousData,
      retry: false,
    })
    return {
      data,
      error,
      isPending,
      isFetching,
      isError,
      refetch,
      isPlaceholderData,
    }
  }

  // SHOW
  const useShow = (slug) => {
    const {
      data,
      error,
      isPending,
      isFetching,
      isError,
      isRefetching,
      refetch,
    } = useQuery({
      queryKey: [queryKey.value, { slug }],
      queryFn: async () => {
        // look if we need to increment news views
        const data = await show(slug)
        await incrementLocalyAndServer(data.data.id)
        return data
      },
      refetchOnMount: false,
      staleTime: 0,
      enabled: slug ? true : false,
      retry: false
    })

    return {
      data,
      error,
      isPending,
      isFetching,
      isRefetching,
      isError,
      refetch,
    }
  }

  // INCREMENT VIEWS
  const incrementLocalyAndServer = async (id) => {
    // get the cookie in client browser
    const viewedNewsIds = getCookie(VIEWS_COOKIE_NAME)
    // verify if newsID is in viewedNewsIDs
    const isAlreadyViewed = viewedNewsIds.some((item) => item == id)
    if (!isAlreadyViewed) {
      // increment localy
      viewedNewsIds.push(id)
      setCookie(VIEWS_COOKIE_NAME, viewedNewsIds)
      // increment in the server
      await incrementViews(id)
    }
    return isAlreadyViewed
  }

  const initializeCookieViewsIds = () => {
    const exist = getCookie(VIEWS_COOKIE_NAME)
    if (!exist) {
      // cookie will expire after One year
      setCookie(VIEWS_COOKIE_NAME, [], 360)
    }
  }

  return {
    useIndex,
    useShow,
    initializeCookieViewsIds,
    errors,
  }
}

export default useNews
